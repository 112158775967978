export default {
  "/sounds/level-up.mp3": {
    "src": "/sounds/level-up.mp3",
    "volume": 1
  },
  "/sounds/pop-up.mp3": {
    "src": "/sounds/pop-up.mp3",
    "volume": 1
  },
  "/sounds/progress-bar.mp3": {
    "src": "/sounds/progress-bar.mp3",
    "volume": 1
  },
  "/sounds/wrapped.mp3": {
    "src": "/sounds/wrapped.mp3",
    "volume": 1
  }
}